<template>
  <travio-center-container grid-width="1/2" :pageTitle="'Supplier Settings Details - ' + form.supplierName">
    <vx-card>
      <label class="text-sm">Setting Name*</label>
      <vs-input class="w-full" v-model="form.name" disabled />
      
      <div class="mt-4">
        <label class="text-sm">Setting Description*</label>
        <vs-textarea v-model="form.description" disabled  />
      </div>

      <div class="mt-4">
        <label class="text-sm">Options</label>
        <v-select v-model="selectedOptions" multiple taggable append-to-body disabled />
      </div>

      <div class="flex items-center mt-4" v-if="selectedOptions.length > 0">
        <vs-switch v-model="form.isOptionsMultiple" disabled />
        <span class="ml-4">Is Options Multiple:</span>
      </div>
      
      <div class="mt-4" v-if="selectedOptions.length > 0">
        <label class="text-sm">Default Value</label>
        <v-select v-if="!form.isOptionsMultiple" :value="selectedDefaultValue" append-to-body :options="defaultValueOptions" disabled />
        <v-select v-if="form.isOptionsMultiple" :value="selectedDefaultValue" multiple append-to-body :options="defaultValueOptions" disabled />
      </div>
      <div v-if="selectedOptions.length === 0" class="mt-4">
        <label class="text-sm">Default Value</label>
        <vs-input class="w-full" v-model="form.defaultValue" disabled />
      </div>
        
      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
      </div>
    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  components: {
    vSelect
  },
  props: {
    supplierId: { required: true },
    settingName: { required: true, type: String }
  },
  data () {
    return {
      form: new Form({
        name: '',
        description: '',
        defaultValue: '',
        options: '',
        isOptionsMultiple: false,
        supplierName: ''
      }),
      selectedOptions: [],
      selectedDefaultValue: null,
      defaultValueOptions: []
    }
  },
  computed: {
  },
  mounted () {
    this.$vs.loading()
    this.$http.get(`api/admin/suppliers/${this.supplierId}/settings/${this.settingName}`)
      .then(response => {
        this.form = new Form(Object.assign(this.form.data(), response.data))
        const responseData = response.data
        if(responseData.options){
          this.selectedOptions = responseData.options.split(',')
          this.defaultValueOptions = this.selectedOptions
          this.selectedDefaultValue = 
            (responseData.defaultValue && responseData.defaultValue.split(',')) || null
        }
        

      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
  },
  methods: {
    handleCancel () {
      this.$router.push({ name: 'admin-supplier-edit', params: { supplierId: this.supplierId, tabIndexProp: 3 }})
    },
  }
}
</script>